import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const HeroWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
`

const ServiceHero = props => (
  <HeroWrapper>
    <Img fluid={props.image.fluid} alt={props.alt} loading="eager" />
  </HeroWrapper>
)
export default ServiceHero
