import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Form from '../components/form'
import Hero from '../components/servicehero'
import Helmet from 'react-helmet'
import { Container } from 'reactstrap'

const Domestic = ({ data }) => (
  <Layout>
    <Helmet
      title={`${data.service.title} in Essex & London | Supreme Cleaning`}
      meta={[
        {
          name: 'description',
          content:
            'Supreme cleaning specialise in a professional, friendly domestic window cleaning service using nothing but pure water.',
        },
      ]}
    />
    <Hero image={data.service.image} />
    <section className="py-5">
      <Container>
        <h1 className="text-center display-3 mb-3">{data.service.title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.service.description.childMarkdownRemark.html,
          }}
        />
      </Container>
    </section>
    <section className="background-blue py-5 text-light">
      <Container
        dangerouslySetInnerHTML={{
          __html: data.service.serviceSummary.childMarkdownRemark.html,
        }}
      />
    </section>
    <section className="py-5">
      <Container>
        <h2 className="text-center pb-5">
          {`Request a ${data.service.title} Quote`}
        </h2>
        <Form name={data.service.title} />
      </Container>
    </section>
  </Layout>
)

export default Domestic

export const query = graphql`
  query ServiceQuery($slug: String!) {
    service: contentfulService(slug: { eq: $slug }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      serviceSummary {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
